// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { orgUserId } from '@src/helper/sassHelper'
const userId = orgUserId()


export const getTaskRequirements = createAsyncThunk('appTasks/getTaskRequirements', async (id, { }) => {
  const response = await axios.post('/taskrequirements/list', { taskId: id })
  return {
    data: response.data.taskrequirements
  }
})

export const addTaskRequirements = createAsyncThunk('appTasks/addTaskRequirements', async (data, { dispatch }) => {
  await axios.post('/taskrequirements/create', data)
  await dispatch(getTaskRequirements(data.taskId))
  return ''
})

export const updateTaskRequirements = createAsyncThunk('appTasks/updateTaskRequirements', async (data, { dispatch }) => {
  await axios.post('/taskrequirements/update', data)
  await dispatch(getTaskRequirements(data.taskId))
  return ''
})

export const deleteTaskRequirements = createAsyncThunk('appTasks/deleteTaskRequirements', async (data, { dispatch }) => {
  await axios.post('/taskrequirements/delete', { id: data?.id, updatedBy: userId })
  await dispatch(getTaskRequirements(data.taskId))
  return ''
})

export const appTasksSlice = createSlice({
  name: 'appTasks',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTaskRequirements.fulfilled, (state, action) => {
        state.data = action.payload.data
      })
  }
})

export default appTasksSlice.reducer
