export const activeOrganizationid = () => {
    const localVariable = localStorage.getItem('defaultOrganization')
    return (localVariable === null || localVariable === 'undefined') ? null : JSON.parse(localVariable)['id']
}

export const defaultOrganization = () => {
    const localVariable = localStorage.getItem('defaultOrganization')
    return (localVariable === null || localVariable === 'undefined') ? null : JSON.parse(localVariable)
}

export const loggedInUser = () => {
    const localVariable = localStorage.getItem('userData')
    return (localVariable === null || localVariable === 'undefined') ? null : JSON.parse(localVariable)
}

export const orgUserId = () => {
    const localVariable = localStorage.getItem('defaultOrganization')
    return (localVariable === null || localVariable === 'undefined') ? null : JSON.parse(localVariable)['orguserid']
}

export const desId = () => {
    const localVariable = localStorage.getItem('defaultOrganization')
    return (localVariable === null || localVariable === 'undefined') ? null : JSON.parse(localVariable)['designationid']
}

