// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { orgUserId } from '@src/helper/sassHelper'
import moment from 'moment'
const userId = orgUserId()

export const getData = createAsyncThunk('appDigitalSignature/getData', async params => {
  const response = await axios.post('/digitalsignatures/list', params)
  return {
    params,
    data: response.data.digitalsignatures.digitalsignatures,
    totalPages: response.data.digitalsignatures.total
  }
})

export const getLicenseData = createAsyncThunk('appDigitalSignature/getLicenseData', async params => {
  const response = await axios.post('/digitalsignatures/licenselist', params)
  return {
    params,
    data: response.data.digitalsignatures.digitalsignatures,
    totalPages: response.data.digitalsignatures.total
  }
})

export const getDsc = createAsyncThunk('appDigitalSignature/getDsc', async id => {
  const response = await axios.post('/digitalsignatures/get', { id })
  return response.data.digitalsignatures
})

export const DSCList = createAsyncThunk('appDigitalSignature/DSCList', async (contactid) => {
  const response = await axios.post(`/digitalsignatures/listbyclient`, { contactid })
  return { data: response.data.digitalsignatures }
})

export const addDsc = createAsyncThunk('appDigitalSignature/addDsc', async (dsc, { dispatch, getState }) => {
  await axios.post('/digitalsignatures/create', dsc)
  await dispatch(getData(getState().digitalsignature.params))
  return ''
})

export const updateDSC = createAsyncThunk('appDigitalSignature/updateDsc', async (dsc, { dispatch, getState }) => {
  await axios.post('/digitalsignatures/update', dsc)
  await dispatch(getData(getState().digitalsignature.params))
  return ''
})

export const deleteDigitalSignature = createAsyncThunk('appDigitalSignature/deleteDigitalSignature', async (id, { }) => {
  await axios.post('/digitalsignatures/delete', { id, updatedBy: userId })
  await dispatch(getData(getState().digitalsignature.params))
  return null
})

export const deleteDigitalSignaturelist = createAsyncThunk('appDigitalSignature/deleteDigitalSignature', async (id, { dispatch, getState }) => {
  await axios.post('/digitalsignatures/delete', { id, updatedBy: userId })
  await dispatch(getData(getState().digitalsignature.params))
  return null
})

export const updateDSCLocation = createAsyncThunk('appDigitalSignature/updateDSCLocation', async (data, { dispatch, getState }) => {
  await axios.post('/digitalsignatures/updatelocation', data)
  await dispatch(getData(getState().digitalsignature.params))
  return null
})

const dateFormat = (val) => {
  if (val.length === 13) {
    return moment(val, 'x').format('MMM DD, Y')
  } else {
    return moment.unix(val).format('MMM DD, Y')
  }

}

export const exportDSC = createAsyncThunk('appTasks/exportDSC', async params => {
  const response = await axios.post(`/digitalsignatures/exportToExcel`, params)
  const status = ['', 'Active', 'Inactive', 'Expired']
  const location = ['', 'Office', 'with Client']
  const result = response.data.digitalsignatures.map((obj) => {

    const data = {}
    data['id'] = obj.uniqueidentity
    data['clientname'] = obj.contactname
    data['email'] = obj.email
    data['contact'] = obj.contact
    data['password'] = obj.password
    data['issueddate'] = dateFormat(obj.issueddate)
    data['expirydate'] = dateFormat(obj.expirydate)
    data['location'] = location[obj.dsclocation]
    data['status'] = status[obj.status]

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['ID', 'Client Name', 'Client Email', 'Conatct', 'Password', 'Issued Date', 'Expiry Date', 'Location', 'Status']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'Digital_Signature_Report.xlsx')

})

export const appDigitalSignatureSlice = createSlice({
  name: 'appDigitalSignature',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedDigitalSignature: null,
    DSCLists: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.DSCLists = []
      })
      .addCase(getLicenseData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.DSCLists = []
      })      
      .addCase(DSCList.fulfilled, (state, action) => {
        state.DSCLists = action.payload.data
      })
      .addCase(getDsc.fulfilled, (state, action) => {
        state.selectedDigitalSignature = action.payload
        state.DSCLists = []
      })
  }
})

export default appDigitalSignatureSlice.reducer
