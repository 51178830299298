// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'

export const createOrganization = createAsyncThunk('appUsers/createOrganization', async (organization, { rejectWithValue }) => {
  try {
    const response = await axios.post('/organizations/create', organization)
    return { organization: response.data.organizations }
  } catch (ex) {
    console.log(ex.response)
    return rejectWithValue(ex.response)
  }
})

export const createOrganizationUser = createAsyncThunk('appUsers/createOrganizationUser', async (organization, { }) => {
  const response = await axios.post('/organizationusers/create', organization)
  return { organizationusers: response.data.organizationusers }
})

export const createOrganizationPlanPackage = createAsyncThunk('appUsers/createOrganizationPlanPackage', async (data, { rejectWithValue }) => {
  try {
    await axios.post('/organizations/createplanpackage', data)
    return
  } catch (ex) {
    console.log(ex.response)
    return rejectWithValue(ex.response)
  }
})

export const activeOrgDetails = createAsyncThunk('appUsers/activeOrgDetails', async (organization, { }) => {
  return organization
})

export const appOrganizationsSlice = createSlice({
  name: 'appOrganizations',
  initialState: {
    data: [],
    loginUser: null,
    loginError: null,
    verifyprocess: false,
    defaultOrganization: null,
    activeOrganizationId: null,
    accessToken: '',
    organizationCreateError: null,
    registerSuccess: false,
    localActiveOrg: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.activeOrganizationId = action.payload.organization.id
      })
      .addCase(createOrganization.rejected, (state, action) => {
        state.organizationCreateError = action.payload.data.errors
      })
      .addCase(createOrganizationUser.fulfilled, (state, action) => {
        state.defaultOrganization = action.payload.organizationusers
        state.registerSuccess = true
      })
      .addCase(activeOrgDetails.fulfilled, (state, action) => {
        state.localActiveOrg = action.payload
      })
  }
})

export default appOrganizationsSlice.reducer
