// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
import { sendMessage } from '@src/socketio.js'

// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { orgUserId } from '@src/helper/sassHelper'
import moment from 'moment'

const userId = orgUserId()
const statusOptions = ['', 'Pending', 'In progress', 'Completed', 'On Hold', 'Cancelled', 'Sent to Review', 'Request Changes']
const priorityOptions = ['', 'Low', 'Medium', 'High']

export const getData = createAsyncThunk('appTasks/getData', async params => {
  const response = await axios.post('/tasks/list', params)
  return {
    params,
    data: response.data.tasks.tasks,
    totalPages: response.data.tasks.total
  }
})

export const getDataStore = createAsyncThunk('appTasks/getDataStore', async params => {
  return params
})

export const getTaskSummary = createAsyncThunk('appTasks/getTaskSummary', async params => {
  const response = await axios.post('/tasks/summary', params)
  return {
    params,
    data: response.data.tasks
  }
})


export const exportTask = createAsyncThunk('appTasks/exportTask', async params => {
  const response = await axios.post(`/tasks/exportToExcel`, params)

  const result = await response.data.tasks.map((obj) => {

    const data = {}
    data['createdon'] = moment(obj.createdon, 'x').format('MMM DD, Y')
    data['uniqueidentity'] = obj.uniqueidentity
    data['priority'] = priorityOptions[obj.priority]
    data['taskstatus'] = statusOptions[obj.taskstatus]
    data['substatus'] = obj.substatusname
    data['startdate'] = obj.startdate.length === 13 ? moment(obj.startdate, 'x').format("MMM DD, YYYY") : moment.unix(obj.startdate).format('MMM DD, YYYY')
    data['enddate'] = obj.enddate.length === 13 ? moment(obj.enddate, 'x').format("MMM DD, YYYY") : moment.unix(obj.enddate).format('MMM DD, YYYY')
    data['client'] = obj.clientname
    data['service'] = obj.servicename
    data['assignee'] = obj.Assignee.map((assign) => assign.name).toString()
    data['reviewer'] = obj.Reviewer.map((assign) => assign.name).toString()
    data['comment'] = obj.comment || ''

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Created On', 'Task Id', 'Prioirty', 'Status', 'Sub Status', 'Start Date', 'End Date', 'Client', 'Service', 'Assignee', 'Reviewer', 'Last Comment']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=UTF-8" })
  FileSaver.saveAs(data, 'task.xlsx')

})

export const exportSummary = createAsyncThunk('appTasks/exportSummary', async params => {
  const response = await axios.post(`/tasks/summary`, params)

  const result = await response.data.tasks.map((obj) => {

    const data = {}
    data['service'] = obj.servicename
    data['todo'] = obj.todo
    data['pending'] = obj.pending
    data['inprogress'] = obj.inprogress
    data['sendforreview'] = obj.sendforreview
    data['requestchanges'] = obj.requestchanges
    data['completed'] = obj.completed

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Service', 'To Do', 'Pending', 'In Progress', 'Send for Review', 'Request Changes', 'Completed']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=UTF-8" })
  FileSaver.saveAs(data, 'Task Summary.xlsx')

})

export const getClient = createAsyncThunk('appTasks/getClient', async id => {
  const response = await axios.post('/clients/get', { id })
  return response.data.clients
})

export const getTask = createAsyncThunk('appTasks/getTask', async (data) => {
  const response = await axios.post('/tasks/get', data)
  return response.data.task
})

export const addTask = createAsyncThunk('appTasks/addTask', async (task, { }) => {
  const response = await axios.post('/tasks/create', task)
  return response.data.task
})

export const addTaskParticipants = createAsyncThunk('appTasks/addTaskParticipants', async (taskparticpants, { }) => {
  await axios.post('/taskparticpants/create', taskparticpants)
  return []
})

export const updateInvocieId = createAsyncThunk('appTasks/updateInvocieId', async (data, { }) => {
  await axios.post('/tasks/updateinvocieid', data)
  return response.data.task
})

export const updateTask = createAsyncThunk('appTasks/updateTask', async (task, { }) => {
  const response = await axios.post('/tasks/update', task)
  return response.data.task
})

export const updateStatusRefresh = createAsyncThunk('appTasks/updateStatusRefresh', async (data, { dispatch }) => {
  await dispatch(getTask({ id: data.taskId, userId: data.userId }))
  return ''
})

export const deleteTaskBulk = createAsyncThunk('appTasks/deleteTaskBulk', async (selectedIds, { dispatch, getState }) => {
  await axios.post('/tasks/deletebulk', { selectedIds, updatedBy: userId })
  await dispatch(getData(getState().invoice.params))
  return id
})

export const deleteTask = createAsyncThunk('appTasks/deleteTask', async (id, { dispatch, getState }) => {
  await axios.post('/tasks/delete', { id, updatedBy: userId })
  await dispatch(getData(getState().task.params))
  return id
})

export const startTimer = createAsyncThunk('appTasks/startTimer', async (data, { dispatch }) => {
  const response = await axios.post('/tasktimesheets/starttimer', data)
  await dispatch(getTask({ id: data.taskId, userId: data.userId }))
  return response.data.timesheets
})

export const endTimer = createAsyncThunk('appTasks/endTimer', async (data, { dispatch }) => {
  await axios.post('/tasktimesheets/endtimer', data)
  await dispatch(getTask({ id: data.taskId, userId: data.userId }))
  return null
})

export const timeSheetList = createAsyncThunk('appTasks/timeSheetList', async (data, { }) => {
  const res = await axios.post('/tasktimesheets/list', data)
  return res.data.tasktimesheets.tasktimesheets
})

export const cloneWorkFlows = createAsyncThunk('appTasks/cloneWorkFlows', async (data, { }) => {
  const res = await axios.post('/taskworkflows/clone', data)
  return res.data.taskworkflows
})

export const checklists = createAsyncThunk('appTasks/checklists', async (data, { }) => {
  const res = await axios.post('/taskworkflows/list', data)
  return res.data.taskworkflows
})

export const addTaskWorkflow = createAsyncThunk('appTasks/addTaskWorkflow', async (taskworkflow, { }) => {
  await axios.post('/taskworkflows/create', taskworkflow)
  return []
})

export const updateTaskWorkflow = createAsyncThunk('appTasks/updateTaskWorkflow', async (taskworkflow, { }) => {
  await axios.post('/taskworkflows/update', taskworkflow)
  return []
})

export const addDescription = createAsyncThunk('appTasks/addDescription', async (data, { }) => {
  const res = await axios.post('/taskconversations/create', data)
  return res.data.taskconversations
})

export const updateDescription = createAsyncThunk('appTasks/updateDescription', async (data, { }) => {
  const res = await axios.post('/taskconversations/update', data)
  return res.data.taskconversations
})

export const updateStatusParams = createAsyncThunk('appTasks/updateStatusParams', async (data, { }) => {
  return data
})

export const sendTaskNotification = createAsyncThunk('appChat/sendTaskNotification', async (obj, { }) => {
  const res = await axios.post('/notifications/sendtasknotification', obj)

  res.data.results.map(async (msg) => {
    await sendMessage(msg.userid, '', msg)
  })
})

export const appTasksSlice = createSlice({
  name: 'appTasks',
  initialState: {
    data: [],
    summaryData: [],
    total: 1,
    params: {},
    allData: [],
    selectedTask: null,
    taskId: null,
    editflag: true,
    selectedTaskTimer: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedTask = null
      })
      .addCase(getDataStore.fulfilled, (state, action) => {
        state.params = action.payload
      })
      .addCase(getTaskSummary.fulfilled, (state, action) => {
        state.summaryData = action.payload.data
      })
      .addCase(getTask.fulfilled, (state, action) => {
        state.selectedTask = action.payload
        state.taskId = null
      })
      .addCase(addTask.fulfilled, (state, action) => {
        state.taskId = action.payload.id
      })
      .addCase(updateTask.fulfilled, (state, action) => {
        state.taskId = action.payload.id
      })
      .addCase(startTimer.fulfilled, (state, action) => {
        state.selectedTaskTimer = action.payload
      })
      .addCase(endTimer.fulfilled, (state, action) => {
        state.selectedTaskTimer = action.payload
      })
      .addCase(updateStatusParams.fulfilled, (state, action) => {
        state.params = action.payload
      })
  }
})

export default appTasksSlice.reducer
