// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { getUserData } from '@utils'
export const getOrganization = createAsyncThunk('appOrganization/getData', async id => {
  const user = getUserData()
  const response = await axios.post(`/organizations/get`, { id, userId : user.id })
  localStorage.setItem('defaultOrganization', JSON.stringify(response.data.organizations))
  return {
    data: response.data.organizations
  }
})

export const getactiveorg = createAsyncThunk('appOrganization/getactiveorg', async id => {
  const response = await axios.post(`/organizations/getactiveorg`, { id })
  Cookies.set('defaultOrganization', JSON.stringify(response.data.organizations))
  localStorage.setItem('defaultOrganization', JSON.stringify(response.data.organizations))
  return {
    data: response.data.organizations
  }
})

export const updateOrganization = createAsyncThunk('appOrganization/addOrgPreference', async (data, { }) => {

  const res = await axios.post(`/organizations/update`, data)
  return res.data.organizations

})

export const paymentStaus = createAsyncThunk('appOrganization/paymentStaus', async (flag, { }) => {
  return flag
})

export const geoLocation = createAsyncThunk('appOrganization/geoLocation', async (coords, { }) => {
  return coords
})


export const appOrganizationSlice = createSlice({
  name: 'appOrganization',
  initialState: {
    organizationData: null,
    paymentStausFlag: false,
    geoCordinate : null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getOrganization.fulfilled, (state, action) => {
        state.organizationData = action.payload.data
      })
      .addCase(getactiveorg.fulfilled, (state, action) => {
        state.organizationData = action.payload.data
      })
      .addCase(paymentStaus.fulfilled, (state, action) => {
        state.paymentStausFlag = action.payload
      })
      .addCase(geoLocation.fulfilled, (state, action) => {
        state.geoCordinate = action.payload
      })
  }
})

export default appOrganizationSlice.reducer
